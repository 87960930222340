import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import useTeamJoin from '@web/hooks/useTeamJoin';
import { AuthContext } from '@web/utils/context';
import ImpactiveModal from '@web/components/common/ImpactiveModal';
import ImpactiveOrCampaignLogo from '@web/components/Auth/Components/ImpactiveOrCampaignLogo';
import ImpactiveButton from '@web/components/ImpactiveButton';
import PoweredByImpactive from '@web/components/Auth/Components/PoweredByImpactive';
import useQueryParam from '@web/hooks/useQueryParam';
import {
  getTeamJoinModal,
  clearTeamJoinModal,
  clearCampaignJoinModal,
  clearOnboardingModal,
} from '@web/services/session';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.h1`
  display: flex;
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 2.5rem;
`;

const CampaignAndTeamJoinCongratsModal = ({ loadCampaign }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const campaign = useSelector(state => state.oneCampaign.selectedCampaign);

  const teamJoinCodeQuery = useQueryParam('team_join_code');
  const showOnboardingModal = useQueryParam('showOnboardingModal');
  const teamJoinCode = teamJoinCodeQuery || getTeamJoinModal();

  const {
    joinTeamAndCampaign,
    clearJoinCode,
    joinedTeamName,
    joinedCampaignName,
    isSubmitting,
    setJoinedCampaignName,
    clearOnboardingModal: clearOnboardingModalSearch,
    isSwitchingReady,
    setIsSwitchingReady,
  } = useTeamJoin({ switchToCampaign: true, teamJoinCode });

  useEffect(() => {
    // Join team if team join code is present and user already viewed onboarding
    if (teamJoinCode && user?.onboarding_viewed && !showOnboardingModal) {
      joinTeamAndCampaign();
    }
  }, [teamJoinCode, user?.onboarding_viewed, joinTeamAndCampaign, showOnboardingModal]);

  const closeModal = useCallback(() => {
    clearJoinCode();
    clearTeamJoinModal();
    clearOnboardingModalSearch();
    setIsSwitchingReady(false);
    clearOnboardingModal();
    clearTeamJoinModal();
    clearCampaignJoinModal();
    loadCampaign?.();
  }, [clearJoinCode, loadCampaign, clearOnboardingModalSearch, setIsSwitchingReady]);

  const closeCampaignModal = useCallback(() => {
    setJoinedCampaignName('');
    clearCampaignJoinModal();
  }, [setJoinedCampaignName]);

  if (isSubmitting || showOnboardingModal || !campaign || !isSwitchingReady) return null;

  return (
    <ImpactiveModal
      visible={joinedCampaignName || joinedTeamName}
      closable={false}
      footer={null}
      width={500}
    >
      <Wrapper>
        <ImpactiveOrCampaignLogo campaign={campaign} withoutCampaignName />
        {joinedCampaignName ? (
          <>
            <Text>
              {t('campaign.onboarding.campaign_congrats', { campaign_name: joinedCampaignName })}
            </Text>
            <ImpactiveButton size="large" onClick={closeCampaignModal}>
              {t('campaign.onboarding.get_started')}
            </ImpactiveButton>
          </>
        ) : (
          <>
            <Text>{t('campaign.onboarding.team_congrats', { team_name: joinedTeamName })}</Text>
            <ImpactiveButton size="large" onClick={closeModal}>
              {t('campaign.onboarding.get_started')}
            </ImpactiveButton>
          </>
        )}
        <PoweredByImpactive withoutCampaignName />
      </Wrapper>
    </ImpactiveModal>
  );
};

CampaignAndTeamJoinCongratsModal.propTypes = {
  loadCampaign: PropTypes.func,
};

export default CampaignAndTeamJoinCongratsModal;
