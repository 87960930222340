import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { campaigns, users } from 'web/services/api';
import { AuthContext } from 'web/utils/context';
import { getLocalizedOnboardingData } from 'web/utils/other';
import { fmtScore, replaceDefaultCampaignImage } from 'web/utils/string';
import { media } from '../../styles/theme';
import Loading from '../common/Loading';
import { setJoinedCampaigns } from '@web/reducers/oneCampaign';
import { useDispatch, useSelector } from 'react-redux';

const Container = styled.div`
  color: ${({ theme }) => theme.colors.trueBlack};
  font-family: ${({ theme }) => theme.fonts.bold};
  padding: 30px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 3px solid ${({ theme }) => theme.colors.borderGray};

  ${media.ns} {
    justify-content: normal;
    align-items: start;
    border-left: 3px solid ${({ theme }) => theme.colors.borderGray};
    border-top: none;
  }
`;

const Picture = styled.img`
  box-shadow: 0px 2px 2px rgba(128, 126, 121, 0.1);
  border-radius: 100%;
  height: 80px;
  width: 80px;
  margin-top: 15px;
  margin-bottom: 15px;
  ${media.ns} {
    height: 176px;
    width: 176px;
    margin-top: 29px;
    margin-bottom: 25px;
  }
`;

const MetricSection = styled.div`
  display: flex;
  padding-bottom: 32px;
`;

const MetricWrapper = styled.div`
  padding-right: 14px;
  text-align: center;
`;

const Metric = styled.div`
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: 24px;
  line-height: 12px;
  padding-bottom: 8px;
`;

const MetricDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 10px;
  line-height: 12px;

  text-transform: uppercase;
`;

const PostedBy = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 12px;
`;

const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 20px;
  padding-bottom: 24px;
`;

const IconsAndDescriptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWithDescription = styled.div`
  margin-bottom: 17px;
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  margin-right: 12px;
  height: 24px;
`;

const IconDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
`;

const ViewCampaign = styled(Link)`
  color: ${({ theme }) => theme.colors.link};
  font-family: ${({ theme }) => theme.fonts.blackItalic};
`;

const JoinCampaignButton = styled(Button)`
  &&& {
    margin-bottom: 18px;
    font-size: 12px;
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

/*
If you're logged in:
  - if you're joined it should say that and let you unjoin a campaign
  - if you're NOT joined it should give you the option to join the campaign(?)
    – unless you've joined a campaign, you can't participate in an activity

If not:
  - clicking join opens a modal to login/create an account
 */

const CampaignRightSidebar = ({ campaignId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { pathname, search } = useLocation();

  const { selectedCampaign } = useSelector(state => state.oneCampaign);

  const campaignJoined =
    token && campaign && campaign.user_campaign ? campaign.user_campaign.joined : false;

  const loadCampaign = React.useCallback(() => {
    // if user is not logged in we use public campaign endpoint
    const getCampaign = token ? campaigns.getCampaign : campaigns.getPublicCampaign;

    return getCampaign({ id: campaignId }).then(({ data: { data } }) => {
      const localizedData = getLocalizedOnboardingData(data);

      setCampaign(localizedData);
      setLoading(false);
    });
  }, [token, campaignId, setCampaign]);

  useEffect(() => {
    // initially load Campaign info based on provided id or slug
    loadCampaign();
  }, [loadCampaign, token]);

  if (loading) {
    return (
      <>
        <div className="flex-row justify-content-center mt-4">
          <Loading />
        </div>
      </>
    );
  }

  const handlePressJoin = async () => {
    if (!token) {
      // If user is not logged in we redirect and keep the url as a return path in localStorage to redirect back to
      localStorage.setItem('redirectAfterLogin', `${pathname}${search}`);
      history.push(`/auth/signup/${campaign.slug}`);
      return;
    }

    const action = campaignJoined ? users.unfollowCampaign : users.followCampaign;

    await action({
      campaign_id: campaign.id,
      campaign_name: campaign.name,
      campaign_slug: campaign.slug,
    });

    await loadCampaign();

    if (selectedCampaign.id === campaign.id && campaignJoined) history.go(0);

    const userCampaigns = await users
      .getUserCampaigns(1, 5000)
      .then(({ data: { data: campaigns } }) => campaigns);

    dispatch(setJoinedCampaigns(userCampaigns));
  };

  const candidateName = campaign.public_short_name || campaign.name;
  const {
    url_profile_img: pictureUrl,
    highlights: attributeList,
    total_actions_completed: actionCount,
    total_allies: alliesCount,
    total_actions_performed: networkCount,
  } = campaign;

  const renderIconsWithDescriptions = () => {
    // change to attribute.icon_url once we have an icon url
    return attributeList.map(attribute => {
      return (
        <IconWithDescription key={attribute.title}>
          <Icon src={attribute.img_url} alt="test" />
          <IconDescription>{attribute.title}</IconDescription>
        </IconWithDescription>
      );
    });
  };

  const renderMetricAndDescription = (metric, name) => {
    return (
      <MetricWrapper>
        <Metric>{fmtScore(metric)}</Metric>
        <MetricDescription>{name}</MetricDescription>
      </MetricWrapper>
    );
  };

  return (
    <Container>
      {!!replaceDefaultCampaignImage(pictureUrl) && (
        <Picture src={replaceDefaultCampaignImage(pictureUrl)} alt={candidateName} />
      )}

      <PostedBy>posted by</PostedBy>
      <SectionTitle>{candidateName}</SectionTitle>

      <MetricSection>
        {renderMetricAndDescription(alliesCount, t('campaign.allies'))}
        {renderMetricAndDescription(actionCount, t('campaign.actions'))}
        {renderMetricAndDescription(networkCount, t('campaign.network'))}
      </MetricSection>

      {!!attributeList && (
        <IconsAndDescriptions>{renderIconsWithDescriptions()}</IconsAndDescriptions>
      )}
      <JoinCampaignButton
        icon={campaignJoined ? <CheckOutlined /> : <PlusOutlined />}
        onClick={handlePressJoin}
        type="primary"
      >
        {campaignJoined ? t('campaign.joined') : t('campaign.join')}
      </JoinCampaignButton>

      <ViewCampaign to={`/campaigns/${campaign.slug}`}>{t('campaign.view_campaign')}</ViewCampaign>
    </Container>
  );
};

CampaignRightSidebar.propTypes = {
  campaignId: PropTypes.number.isRequired,
};

export default CampaignRightSidebar;
