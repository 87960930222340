import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ImpactiveLogo from './ImpactiveLogo';
import { replaceDefaultCampaignImage } from '@web/utils/string';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
  align-items: center;

  @media (max-height: 850px) {
    margin-bottom: 1rem;
  }
  @media (max-height: 750px) {
    margin-bottom: 0.5rem;
  }
`;

const CampaignLogo = styled.img`
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: cover;

  @media (max-height: 850px) {
    height: 8rem;
    width: 8rem;
  }
  @media (max-height: 750px) {
    height: 6rem;
    width: 6rem;
  }
`;

const CampaignName = styled.div`
  margin-top: 0.5rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.blue};

  @media (max-height: 850px) {
    margin-top: 0.5rem;
    font-size: 1.25rem;
  }
  @media (max-height: 750px) {
    margin-top: 0.125rem;
    font-size: 1rem;
  }
`;

const ImpactiveOrCampaignLogo = ({ campaign, withoutCampaignName }) => {
  return campaign ? (
    <LogoContainer>
      <CampaignLogo
        source={{ uri: replaceDefaultCampaignImage(campaign.url_profile_img) }}
        src={replaceDefaultCampaignImage(campaign.url_profile_img)}
        alt={campaign.name}
      />
      {!withoutCampaignName && <CampaignName>{campaign.name}</CampaignName>}
    </LogoContainer>
  ) : (
    <ImpactiveLogo />
  );
};

ImpactiveOrCampaignLogo.propTypes = {
  campaign: PropTypes.object,
  withoutCampaignName: PropTypes.bool,
};

export default ImpactiveOrCampaignLogo;
