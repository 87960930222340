import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { users } from '@web/services/api';
import { AuthContext } from '@web/utils/context';
import { useDocumentTitle } from '@web/hooks/useDocumentTitle';
import { SEO } from '@web/components/common';
import { replaceDefaultCampaignImage } from '@web/utils/string';
import { setJoinedCampaigns } from '@web/reducers/oneCampaign';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import styled from 'styled-components';
import { media } from '@web/styles/theme';

import AboutUsSection from '@web/components/common/AboutUsSection';

import CampaignHero from './components/CampaignHero';
import CampaignActions from './components/CampaignActions';
import CampaignTakeAction from './components/CampaignTakeAction';

const CampaignContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Footer = styled.div`
  display: none;

  ${media.lg} {
    display: block;
    height: 320px;
    background: ${({ theme }) => theme.colors.white};
  }
`;

const PublicCampaignDetails = ({ campaign, setCampaign }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { redirect } = queryString.parse(search);

  useDocumentTitle(campaign.name);

  const { selectedCampaign } = useSelector(state => state.oneCampaign);
  // Get document title for <SEO />
  const title = campaign?.name || null;
  const campaignJoined = (token && campaign?.user_campaign?.joined) || false;

  useEffect(() => {
    // redirect unauthenticated users to signup page
    // if they try to access a campaign page from join link with empty redirect query param or with redirect=true
    if (campaign?.slug && (!redirect || redirect !== 'false') && !token) {
      localStorage.setItem('redirectAfterLogin', `${pathname}${search}`);
      history.push(`/auth/signup/${campaign.slug}`);
    }
  }, [token, redirect, history, pathname, campaign.slug, search]);

  // MTS - I'd really like to move this to the Header component but it has some shared logic so
  // we'll leave it here for now
  const joinCampaign = async () => {
    if (!token) {
      // If user is not logged in we redirect and keep the url as a return path in localStorage to redirect back to
      localStorage.setItem('redirectAfterLogin', `${pathname}${search}`);

      history.push(`/auth/signup/${campaign.slug}`);
      return;
    }

    const action = campaignJoined ? users.unfollowCampaign : users.followCampaign;

    await action({
      campaign_id: campaign.id,
      campaign_name: campaign.name,
      campaign_slug: campaign.slug,
    }).then(({ data: { data: userCampaign } }) => {
      // Update internal campaign state
      setCampaign({
        ...campaign,
        joined: userCampaign.joined,
        user_campaign: userCampaign,
      });
    });

    if (selectedCampaign.id === campaign.id && campaignJoined) history.go(0);

    const userCampaigns = await users
      .getUserCampaigns(1, 5000)
      .then(({ data: { data: campaigns } }) => campaigns);

    dispatch(setJoinedCampaigns(userCampaigns));
  };

  const mediaUrl = campaign.public_join_video_url || campaign.url_hero_img;
  const mediaContentType = campaign.public_join_video_url ? 'video' : 'image';

  if (!campaign) {
    return null;
  }

  return (
    <CampaignContainer>
      <SEO
        description={campaign && campaign.description}
        image={campaign && replaceDefaultCampaignImage(campaign.url_profile_img)}
        title={title}
      />

      <CampaignHero campaign={campaign} joined={campaignJoined} handleGetStarted={joinCampaign} />

      <AboutUsSection
        mediaUrl={mediaUrl}
        mediaContentType={mediaContentType}
        title={t('campaign.about_us')}
        description={campaign.description}
      />

      <CampaignActions
        campaign={campaign}
        campaignJoined={campaignJoined}
        joinCampaign={joinCampaign}
      />

      <CampaignTakeAction
        campaign={campaign}
        joined={campaignJoined}
        handleGetStarted={joinCampaign}
      />

      <Footer />
    </CampaignContainer>
  );
};

PublicCampaignDetails.propTypes = {
  campaign: PropTypes.object.isRequired,
  setCampaign: PropTypes.func.isRequired,
};

PublicCampaignDetails.defaultProps = {
  campaign: {
    total_actions_completed: 0,
    total_actions_performed: 0,
    url_hero_img: null,
    url_profile_img: null,
  },
};

export default PublicCampaignDetails;
