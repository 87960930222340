import { Spin, message } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { user } from '../../services/api';
import { AuthContext } from '../../utils/context';
import ActivityDetailCore from '../ActivityDetail/ActivityDetailCore';
import FanOutActivity from '@web/components/FanOut';
import FanOutVotingPlanActivity from '@web/components/FanOutVotingPlan';
import HeadcountRegistrationActivity from '@web/components/Headcount';
import RegistrationActivity from '@web/components/RegistrationActivity';
import SurveyActivity from '@web/components/SurveyActivity';

const PublicActivityDetail = () => {
  const { t } = useTranslation();

  const { token } = useContext(AuthContext);
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { referral_hash } = queryString.parse(search);

  const { activitySlug, campaignSlug } = useParams();
  const [activity, setActivity] = useState(null);

  const [loading, setLoading] = useState(true);
  const [redirectingToUserActivity, setRedirectingToUserActivity] = useState(false);

  const loadActivity = useCallback(() => {
    setLoading(true);
    user
      .getPublicActivity(campaignSlug, activitySlug)
      .then(({ data: activity }) => {
        setActivity(activity);
        setLoading(false);
      })
      .catch(e => {
        setActivity(null);
        setLoading(false);
      });
  }, [campaignSlug, activitySlug]);

  const handlePressJoin = () => {
    localStorage.setItem('redirectAfterLogin', `${pathname}${search}`);
    history.push(`/auth/signup/${activity.campaign.slug}`);
  };

  // load activity on init and when explicitly requested
  useEffect(() => {
    loadActivity();
  }, [loadActivity]);

  // If logged in, go to the user_activity page if available, or fallback to campaign page
  useEffect(() => {
    if (token) {
      setRedirectingToUserActivity(true);
      user
        .lookupUserActivity(campaignSlug, activitySlug)
        .then(({ data: { activity, id } }) => {
          // Early return for RegistrationActivity, we don't want to redirect to a user activity in this case
          if (activity.type === 'RegistrationActivity' || activity.type === 'SurveyActivity') {
            return;
          }
          if (id) {
            return history.replace(`/activities/${id}`);
          }
          return Promise.reject();
        })
        .catch(e => {
          // we'd want to change this to view activity for non-joined users
          message.warning(t('activity.need_join_campaign_first'));
          history.replace(`/campaigns/${campaignSlug}`);
        })
        .finally(() => {
          setLoading(false);
          setRedirectingToUserActivity(false);
        });
    }
  }, [activitySlug, campaignSlug, history, t, token]);

  // Render spinner as long as the activity is being loaded, or redirect is pending
  if (loading || !activity || redirectingToUserActivity) {
    return <Spin />;
  }
  // Special case for RegistrationActivity
  if (activity.type === 'RegistrationActivity') {
    if (activity.campaign?.fan_out_registration_enabled) {
      return <FanOutActivity activity={activity} />;
    }

    if (activity.campaign?.headcount_registration_enabled) {
      return <HeadcountRegistrationActivity activity={activity} />;
    }
    return <RegistrationActivity activity={activity} />;
  }

  // Special case for SurveyActivity
  if (activity.type === 'SurveyActivity') {
    if (activity.id === activity.campaign.voting_plan_activity_id && referral_hash) {
      return <FanOutVotingPlanActivity activity={activity} />;
    }

    return <SurveyActivity activity={activity} />;
  }

  // Any other public activity - show regular activity page for now
  return (
    <ActivityDetailCore
      title={t('activity.header_title')}
      appContainerDataTestId={'logged_out_activity_detail'}
      activity={activity}
      completed={false}
      headerTitle={activity.campaign.name || t('activity.header_title')}
      userActivity={null}
      campaign={activity.campaign}
      onActivityActionButtonPress={handlePressJoin}
    />
  );
};

PublicActivityDetail.propTypes = {};

export default PublicActivityDetail;
