import { useCallback, useState, useContext } from 'react';

import { teams, users, campaigns } from '@web/services/api';
import { AuthContext } from '@web/utils/context';
import { useDispatch } from 'react-redux';
import { setCampaign, setJoinedCampaigns } from '@web/reducers/oneCampaign';
import { teamJoinCodeError } from '@web/utils/errorHandler';

const useTeamJoin = ({ teamJoinCode, switchToCampaign }) => {
  const [joinedCampaignName, setJoinedCampaignName] = useState('');
  const [joinedTeamName, setJoinedTeamName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSwitchingReady, setIsSwitchingReady] = useState(false);

  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();

  const joinTeam = useCallback(() => {
    teams
      .find_by_code({ join_code: teamJoinCode })
      .then(({ data }) => {
        if (data?.data?.id) {
          teams
            .joinTeam({ id: data?.data?.id })
            .then(() => {
              users.followCampaign({
                campaign_id: data?.data?.campaign_id,
                campaign_name: data?.data?.campaign_name,
                campaign_slug: data?.data?.campaign_slug,
              });
              setJoinedTeamName(data?.data?.name);
            })
            .catch(err => {});
        }
      })
      .catch(err => {});
  }, [teamJoinCode, setJoinedTeamName]);

  const clearJoinCode = useCallback(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete('team_join_code');
    window.history.replaceState({}, '', url);
    window.dispatchEvent(new Event('replaceState'));

    setJoinedTeamName('');
    setJoinedCampaignName('');
  }, []);

  const clearOnboardingModal = useCallback(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete('showOnboardingModal');
    window.history.replaceState({}, '', url);
    window.dispatchEvent(new Event('replaceState'));
  }, []);

  const switchToOneCampaign = useCallback(
    campaign => {
      campaigns.getCampaign({ id: campaign.id }).then(res => {
        dispatch(setCampaign(res.data.data));
        setIsSwitchingReady(true);
      });
      users
        .getUserCampaigns(1, 5000)
        .then(({ data: { data: campaigns } }) => dispatch(setJoinedCampaigns(campaigns)));
      users.updateMe({ last_campaign_id: campaign.id });
    },
    [dispatch],
  );

  const joinTeamAndCampaignAfterTeamSearch = useCallback(
    async data => {
      teams.joinTeam({ id: data.id });

      setJoinedCampaignName(user?.last_campaign_id !== data.campaign_id ? data.campaign_name : '');
      setIsSubmitting(false);

      // switch to campaign to be active campaign
      if (switchToCampaign) {
        switchToOneCampaign({
          id: data.campaign_id,
          name: data.campaign_name,
          slug: data.campaign_slug,
        });
      }
    },
    [
      setJoinedCampaignName,
      setIsSubmitting,
      switchToCampaign,
      switchToOneCampaign,
      user?.last_campaign_id,
    ],
  );

  const joinCampaign = useCallback(
    async campaignFromCode => {
      users.followCampaign({
        campaign_id: campaignFromCode.id,
        campaign_name: campaignFromCode.name,
        campaign_slug: campaignFromCode.slug,
      });

      setJoinedCampaignName(
        user?.last_campaign_id !== campaignFromCode.id ? campaignFromCode.name : '',
      );
      setIsSubmitting(false);

      // switch to campaign to be active campaign
      if (switchToCampaign) {
        switchToOneCampaign(campaignFromCode);
      }
    },
    [
      setJoinedCampaignName,
      setIsSubmitting,
      user?.last_campaign_id,
      switchToCampaign,
      switchToOneCampaign,
    ],
  );

  const joinTeamAndCampaign = useCallback(() => {
    // try join to team by team code
    teams
      .find_by_code({ join_code: teamJoinCode })
      .then(({ data }) => {
        // success
        joinTeamAndCampaignAfterTeamSearch(data.data);
        setJoinedTeamName(data.data.name);
      })
      .catch(error => {
        // try join to campaign by campaign code
        campaigns
          .findCampaign(teamJoinCode)
          .then(({ data }) => {
            if (!data) {
              setIsSubmitting(false);
            } else {
              // success
              setIsSubmitting(false);
              joinCampaign(data.data);
            }
          })
          .catch(error => {
            setIsSubmitting(false);
            teamJoinCodeError(error);
          });
      });
  }, [teamJoinCode, setIsSubmitting, joinCampaign, joinTeamAndCampaignAfterTeamSearch]);

  return {
    clearJoinCode,
    clearOnboardingModal,
    isSubmitting,
    isSwitchingReady,
    joinedCampaignName,
    joinedTeamName,
    joinTeam,
    joinTeamAndCampaign,
    setIsSwitchingReady,
    setJoinedCampaignName,
    setJoinedTeamName,
  };
};

export default useTeamJoin;
