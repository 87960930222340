import React, { useContext, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import { getLocalizedOnboardingData, sortByDisplayOrderFn } from 'web/utils/other';
import { track } from '../../../services/analytics';
import { campaigns, users as usersApi } from '../../../services/api';
import { AuthContext } from '../../../utils/context';
import { isAuthFinished, isShowCampaignOnboarding } from '@web/utils/other';
import useTeamJoin from '@web/hooks/useTeamJoin';
import ImpactiveModal from '../ImpactiveModal';
import UserDetails from './UserDetails';
import Instructions from './Instructions';
import CustomFields from './CustomFields';
import OnboardingCongrats from './OnboardingCongrats';
import useQueryParam from '@web/hooks/useQueryParam';
import {
  clearOnboardingModal,
  getTeamJoinModal,
  clearCampaignJoinModal,
  clearTeamJoinModal,
} from 'web/services/session';

const USER_DETAIL_SCREEN = 'user_detail_screen';
const INSTRUCTIONS_SCREEN = 'instructions_screen';
const CUSTOM_FIELDS_SCREEN = 'custom_fields_screen';
const CAMPAIGN_CONGRATS_SCREEN = 'campaign_congrats_screen';
const TEAM_CONGRATS_SCREEN = 'team_congrats_screen';

/**
 * Modal dialog that shows welcome instructions when user intially logs in/signs up
 */
const OnboardingModal = ({ onCloseCallback = () => {}, loadCampaign }) => {
  const { t } = useTranslation();
  const { token, user, setUser, isLockedToCampaign } = useContext(AuthContext);

  const [displayModal, setDisplayModal] = useState(true);
  const [campaign, setCampaign] = useState(null);
  const [CFs, setCFs] = useState([]);
  const [screen, setScreen] = useState(null);

  const teamJoinCodeQuery = useQueryParam('team_join_code');
  const showOnboardingModal = useQueryParam('showOnboardingModal');
  const teamJoinCode = teamJoinCodeQuery || getTeamJoinModal();

  const {
    joinTeamAndCampaign,
    clearJoinCode,
    joinedTeamName,
    clearOnboardingModal: clearOnboardingModalSearch,
  } = useTeamJoin({
    switchToCampaign: true,
    teamJoinCode,
  });

  useEffect(() => {
    // We only care about onboarding instructions for logged in users
    if (!token || !user) return;
    // Join team if team join code is present and user already viewed onboarding
    if (teamJoinCode) {
      joinTeamAndCampaign();
    }
  }, [teamJoinCode, joinTeamAndCampaign, token, user]);

  const clearTeamJoinCode = useCallback(() => {
    if (!teamJoinCode) return;

    clearJoinCode();
    loadCampaign?.();
  }, [teamJoinCode, clearJoinCode, loadCampaign]);

  const closeModal = useCallback(() => {
    track('CLOSED_ONBOARDING_MODAL');
    usersApi.updateMe({ onboarding_viewed: true }).then(res => {
      const {
        data: { data: current_user },
      } = res;
      setDisplayModal(false);
      setScreen(null);
      onCloseCallback();
      clearTeamJoinCode();
      clearOnboardingModalSearch();
      clearOnboardingModal();
      clearTeamJoinModal();
      clearCampaignJoinModal();
      setUser(current_user);
    });
  }, [onCloseCallback, setUser, clearTeamJoinCode, clearOnboardingModalSearch]);

  useEffect(() => {
    track('VIEW_INSTRUCTIONS_SCREEN');
  }, []);

  useEffect(() => {
    // We only care about onboarding instructions for logged in users
    if (!token || !user) return;

    const { onboarding_viewed } = user;

    // If user is not authenticated, show user detail screen
    if (!isAuthFinished(user)) {
      setScreen(USER_DETAIL_SCREEN);
      setDisplayModal(true);
    }

    // Only continue if user has not seen welcome instructions yet
    // Or if user joined a new campaign via join code
    if (onboarding_viewed && !showOnboardingModal) return;

    const campaignId = user.locked_campaign_id || user.last_campaign_id;

    // If no campaign id, do nothing. useOneCampaign will handle the rest
    if (!campaignId) return;

    campaigns.getCampaign({ id: campaignId }).then(({ data: { data } }) => {
      const localizedData = getLocalizedOnboardingData(data);
      const onboardingCFs =
        data.custom_fields?.filter(cf => cf.onboarding_question)?.sort(sortByDisplayOrderFn) || [];

      setCampaign(localizedData);
      setCFs(onboardingCFs);

      if (!isAuthFinished(user)) return;

      if (isShowCampaignOnboarding(localizedData) || !!onboardingCFs?.length) {
        setScreen(isShowCampaignOnboarding(data) ? INSTRUCTIONS_SCREEN : CUSTOM_FIELDS_SCREEN);
        setDisplayModal(true);
      } else {
        setScreen(CAMPAIGN_CONGRATS_SCREEN);
        setDisplayModal(true);
      }
    });

    // Retrigger effect whenever context flags are updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLockedToCampaign, user, showOnboardingModal]);

  const onUserDetailSubmit = useCallback(
    (values, setSubmitting) => {
      // clearSearch();
      setSubmitting(true);
      const { first_name, last_name, phone, zip, locale } = values;

      usersApi
        .updateMe({
          first_name,
          last_name,
          locale,
          phone: `+${phone}`,
          supplied_zip_code: zip,
        })
        .then(() => {
          // If user has already seen onboarding instructions, close modal
          if (user?.onboarding_viewed && !showOnboardingModal) {
            closeModal();
            return;
          }

          if (isShowCampaignOnboarding(campaign)) {
            setScreen(INSTRUCTIONS_SCREEN);
            return;
          }
          if (CFs?.length) {
            setScreen(CUSTOM_FIELDS_SCREEN);
            return;
          }
          setScreen(CAMPAIGN_CONGRATS_SCREEN);
        })
        .catch(error => {
          message.error(
            error?.response?.data?.message || error?.message || t('common.something_went_wrong'),
          );
        })
        .finally(() => setSubmitting(false));
    },
    [CFs, campaign, t, closeModal, showOnboardingModal, user?.onboarding_viewed],
  );

  const onInstructionsFinish = useCallback(() => {
    setScreen(CFs?.length ? CUSTOM_FIELDS_SCREEN : CAMPAIGN_CONGRATS_SCREEN);
  }, [CFs]);

  const handleSubmitReport = useCallback(() => {
    if (!campaign || !user || !CFs?.length) {
      return;
    }

    const userReportBody = {
      campaign_id: campaign.id,
      customizations_attributes: CFs.map(c => {
        return {
          custom_field_id: c.id,
          value: c.value || '',
        };
      }),
      reportable_id: user.id,
      reportable_type: 'User',
    };

    campaigns.submitOnboardingReport({ campaign_id: campaign.id }, userReportBody).then(() => {
      setScreen(CAMPAIGN_CONGRATS_SCREEN);
    });
  }, [campaign, CFs, user]);

  const onOnboardingCongratsFinish = useCallback(() => {
    if (screen === CAMPAIGN_CONGRATS_SCREEN) {
      joinedTeamName || getTeamJoinModal() ? setScreen(TEAM_CONGRATS_SCREEN) : closeModal();
    } else {
      closeModal();
    }
  }, [closeModal, screen, joinedTeamName]);

  return (
    <ImpactiveModal visible={displayModal && screen} closable={false} footer={null} width={700}>
      <div className="d-flex flex-column align-items-center">
        {screen === USER_DETAIL_SCREEN ? (
          <UserDetails onSubmit={onUserDetailSubmit} user={user} />
        ) : screen === INSTRUCTIONS_SCREEN ? (
          <Instructions campaign={campaign} onInstructionsFinish={onInstructionsFinish} />
        ) : screen === CUSTOM_FIELDS_SCREEN ? (
          <CustomFields
            campaign={campaign}
            CFs={CFs}
            setCFs={setCFs}
            handleSubmitReport={handleSubmitReport}
          />
        ) : screen === CAMPAIGN_CONGRATS_SCREEN ? (
          <OnboardingCongrats
            type="campaign"
            campaign={campaign}
            onSubmit={onOnboardingCongratsFinish}
            isLastScreen={!joinedTeamName || !teamJoinCode}
          />
        ) : screen === TEAM_CONGRATS_SCREEN ? (
          <OnboardingCongrats
            type="team"
            campaign={campaign}
            onSubmit={onOnboardingCongratsFinish}
            joinedTeamName={joinedTeamName}
            isLastScreen
          />
        ) : (
          <div />
        )}
      </div>
    </ImpactiveModal>
  );
};

OnboardingModal.propTypes = {
  loadCampaign: PropTypes.func,
  onCloseCallback: PropTypes.func,
};

export default OnboardingModal;
