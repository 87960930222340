import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { users } from '../../../services/api';
import { AuthContext } from '../../../utils/context';
import AppContainer from '../../AppContainer';
import SwitchCampaignAlert from '../../common/SwitchCampaignAlert';
import { useDispatch, useSelector } from 'react-redux';
import { setJoinedCampaigns } from '@web/reducers/oneCampaign';
import { replaceDefaultCampaignImage } from '@web/utils/string';

// Sub components
import AppHeader from '../../AppHeader';
import { SEO } from '../../common';
import MaxWidthContainer from '../../common/MaxWidthContainer';
import OnboardingModal from '../../common/OnboardingModal';
import ActivityList from './ActivityList';
import CampaignHeader from './CampaignHeader';
import CampaignInfo from './CampaignInfo';
import CampaignAndTeamJoinCongratsModal from './CampaignAndTeamJoinCongratsModal';

const CampaignDetails = ({ campaign, loadCampaign, setCampaign }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const history = useHistory();
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const withOnboarding = query.get('withOnboarding');
  const showOnboardingModal = query.get('showOnboardingModal');
  const initialActivityId = query.get('activityId');

  const { selectedCampaign } = useSelector(state => state.oneCampaign);
  // Get document title for <SEO />
  const title = campaign && campaign.name ? campaign.name : null;

  const campaignJoined =
    token && campaign && campaign.user_campaign ? campaign.user_campaign.joined : false;

  // Check for join-success param on initial load
  useEffect(() => {
    // check string value
    const justJoined = query.get('join-success') === 'true';
    if (justJoined) {
      message.success(`You've successfully joined the Campaign!`);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (campaign && campaign.id) {
      // Effect to determine if onboarding welcome screen should be shown
      const showOnboardingCampaignId = JSON.parse(localStorage.getItem('showOnboarding'));
      if (token && showOnboardingCampaignId === campaign.id) {
        localStorage.removeItem('showOnboarding');
      }
    }
  }, [campaign, campaignJoined, loadCampaign, token, withOnboarding]); // reload data whenever any of these params change

  // MTS - I'd really like to move this to the Header component but it has some shared logic so
  // we'll leave it here for now
  const handlePressJoin = async () => {
    if (!token) {
      // If user is not logged in we redirect and keep the url as a return path in localStorage to redirect back to
      localStorage.setItem('redirectAfterLogin', `${pathname}${search}`);

      history.push(`/auth/signup/${campaign.slug}`);
      return;
    }

    const action = campaignJoined ? users.unfollowCampaign : users.followCampaign;

    await action({
      campaign_id: campaign.id,
      campaign_name: campaign.name,
      campaign_slug: campaign.slug,
    }).then(({ data: { data: userCampaign } }) => {
      // Update internal campaign state
      setCampaign({
        ...campaign,
        joined: userCampaign.joined,
        user_campaign: userCampaign,
      });
    });

    if (selectedCampaign?.id === campaign.id && campaignJoined) history.go(0);

    const userCampaigns = await users
      .getUserCampaigns(1, 5000)
      .then(({ data: { data: campaigns } }) => campaigns);

    dispatch(setJoinedCampaigns(userCampaigns));
  };

  return (
    <>
      <AppHeader title={t('campaign.title')} extra={campaign && campaign.name} />
      <SEO
        description={campaign && campaign.description}
        image={campaign && replaceDefaultCampaignImage(campaign.url_profile_img)}
        title={title}
      />
      <AppContainer data-testid="logged-in-campaign-detail">
        <MaxWidthContainer>
          {campaign && campaign.id && (
            <>
              <SwitchCampaignAlert campaign={campaign} onSwitchCampaign={setCampaign} />
              <CampaignHeader
                campaign={campaign}
                joined={campaignJoined}
                onJoinClick={handlePressJoin}
              />
              <CampaignInfo campaign={campaign} />
              <ActivityList
                campaign={campaign}
                campaignJoined={campaignJoined}
                joinCampaign={handlePressJoin}
                initialActivityId={initialActivityId}
              />
            </>
          )}
        </MaxWidthContainer>
      </AppContainer>

      <OnboardingModal onCloseCallback={loadCampaign} loadCampaign={loadCampaign} />

      {!showOnboardingModal && <CampaignAndTeamJoinCongratsModal loadCampaign={loadCampaign} />}
    </>
  );
};

CampaignDetails.propTypes = {
  campaign: PropTypes.object.isRequired,
  loadCampaign: PropTypes.func.isRequired,
  setCampaign: PropTypes.func.isRequired,
};

export default CampaignDetails;
