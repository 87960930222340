import React, { useContext, useMemo, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import ImpactiveImageUpload, { imagesTypes } from '../common/ImpactiveImageUpload';
import ImpactiveModal from '@web/components/common/ImpactiveModal';
import { Formik } from 'formik';
import ImpactiveButton from '../ImpactiveButton';
import SelectComponent from '../common/shared/Select';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { Progress } from 'antd';
import { useDispatch } from 'react-redux';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { user as userAPI, auth as authApi } from 'web/services/api';
import { AuthContext } from 'web/utils/context';
import { setAppLanguage } from '../i18n';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import EditUserScheme from './EditUserFormScheme';
import FormInput from '../common/shared/FormInput';
import { media } from '@web/styles/theme';
import { removeSelectedCampaign } from '@web/reducers/oneCampaign';

const StyledDropzoneS3Uploader = styled(ImpactiveImageUpload)`
  &&& {
    display: flex;
    justify-content: center;
    overflow: hidden;
    ${({ $empty, $placeholder }) =>
      $empty &&
      css`
        border: 2px dashed rgb(153, 153, 153);

        &:before {
          content: '${$placeholder}';
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.9em;
          padding: 20px;
        }
      `}
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    font-size: 2rem;
    font-family: ${theme.fonts.bold};
    color: ${theme.colors.blue};
    margin-bottom: 2rem;
  `}
`;

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  ${media.xl} {
    grid-template-columns: 1fr 1fr;
  }
`;

const TopBlock = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
  ${media.xl} {
    gap: 50px;
    margin-bottom: 30px;
    flex-direction: row;
  }
`;

const NameFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 20px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 3.5rem;
  > button {
    min-width: 100px;
  }
`;

const AvatarWrapper = styled.div`
  width: fit-content;
`;

const locales = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Español',
    value: 'es',
  },
  {
    label: '한국어',
    value: 'ko',
  },
];

const EditUserModal = ({ user, visible, onCancel }) => {
  const { setAuth, setUser } = useContext(AuthContext);
  const [deleted, setDeleted] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const formInitialValues = useMemo(() => {
    const { first_name, last_name, phone, profile_url, supplied_zip_code, locale } = user;
    return {
      firstName: first_name,
      lastName: last_name,
      locale: locale,
      phone: phone,
      profileUrl: profile_url,
      zipCode: supplied_zip_code,
    };
  }, [user]);

  const onSubmit = values => {
    const { firstName, lastName, phone, zipCode, locale, profileUrl } = values;
    userAPI
      .updateProfile({ firstName, lastName, locale, phone, profileUrl, zipCode })
      .then(({ data: { data } }) => {
        message.success(t('settings.updated_message'));
        setAppLanguage(locale);
        setUser(data); // Update AuthContext user
        onCancel();
      })
      .catch(() => {
        message.error(t('errors.action_failed'));
      });
  };

  const onDelete = () => {
    authApi.deleteAccount(user.id).then(() => {
      setUser(null);
      setAuth(null);
      setDeleted(true);
      dispatch(removeSelectedCampaign());
      message.success(t('settings.deleted_message'));
    });
  };

  const onPhoneChangeHandler = (number, setFieldValue) => {
    // Add `+` prefix if user starts entering digits
    if (/^\d{1}$/.test(number)) {
      setFieldValue('phone', `+1${number}`);
      return;
    }

    // Only allow numbers, starting with + symbol
    if (/^$|^(?:\+1)\d*$/.test(number)) {
      setFieldValue('phone', number);
      return;
    }
  };

  const onZipChangeHandler = (zip, setFieldValue) => {
    if (/^\d{0,5}$/.test(zip)) {
      setFieldValue('zipCode', zip);
      return;
    }
  };

  const showLoading = React.useMemo(
    () => uploadProgress > 0 && uploadProgress < 100,
    [uploadProgress],
  );

  if (deleted) return <Redirect to={'/auth'} />;

  return (
    <>
      <ImpactiveModal style={{ minWidth: '40%' }} visible={visible} onCancel={onCancel}>
        <Title>{t('settings.edit_user_details')}</Title>
        <Formik
          ref={formRef}
          initialValues={formInitialValues}
          validationSchema={EditUserScheme}
          onSubmit={onSubmit}
          render={({ values, setFieldValue, isValid }) => (
            <>
              <TopBlock>
                <AvatarWrapper>
                  <StyledDropzoneS3Uploader
                    style={{ borderRadius: '100%', height: 150, width: 150 }}
                    name="profileUrl"
                    multiple={false}
                    accept={imagesTypes}
                    maxSize={1024 * 1024 * 10}
                    onFinish={value =>
                      setFieldValue('profileUrl', value?.signedUrl.split('?')[0] ?? null)
                    }
                    onProgress={setUploadProgress}
                    shouldShowManageButtons={!!values.profileUrl}
                    $placeholder={t('profile.upload_image_placeholder')}
                    $empty={!values.profileUrl}
                  >
                    {!!values.profileUrl && (
                      <img src={values.profileUrl} alt={t('profile.image_alt')} />
                    )}
                  </StyledDropzoneS3Uploader>
                  {showLoading && <Progress percent={uploadProgress} size="small" />}
                </AvatarWrapper>
                <NameFields style={{ flex: 1 }}>
                  <FormInput
                    name="firstName"
                    label={t('placeholders.first_name')}
                    textTransform={'capitalize'}
                    placeholder={t('placeholders.first_name')}
                    onChange={v => setFieldValue('firstName', v.target.value)}
                    value={values.firstName}
                  />
                  <FormInput
                    name="lastName"
                    label={t('placeholders.last_name')}
                    textTransform={'capitalize'}
                    placeholder={t('placeholders.last_name')}
                    onChange={v => setFieldValue('lastName', v.target.value)}
                    value={values.lastName}
                  />
                </NameFields>
              </TopBlock>

              <FieldsWrapper>
                <FormInput
                  name="phone"
                  label={t('placeholders.phone_number')}
                  textTransform={'capitalize'}
                  placeholder={t('placeholders.phone_number')}
                  onChange={v => onPhoneChangeHandler(v.target.value, setFieldValue)}
                  value={values.phone}
                />
                <FormInput
                  name="zipCode"
                  placeholder={t('placeholders.zip')}
                  label={t('placeholders.zip')}
                  textTransform={'capitalize'}
                  value={values.zipCode}
                  onChange={v => onZipChangeHandler(v.target.value, setFieldValue)}
                />
                <SelectComponent
                  placeholder={t('frontline.filters.filter_by_issue')}
                  label={t('placeholders.preferred_language')}
                  value={values.locale}
                  options={locales}
                  defaultValue="en"
                  minWidth="unset"
                  onChange={val => setFieldValue('locale', val)}
                  labelProps={{ bold: true }}
                />
              </FieldsWrapper>

              <ButtonWrapper>
                <ImpactiveButton
                  onClick={() => setShowConfirmModal(true)}
                  style={{ background: 'red' }}
                >
                  {t('settings.delete_account')}
                </ImpactiveButton>

                <ImpactiveButton disabled={!isValid} onClick={() => formRef.current.submitForm()}>
                  {t('common.save')}
                </ImpactiveButton>
              </ButtonWrapper>
            </>
          )}
        />
      </ImpactiveModal>
      <ConfirmDeleteModal
        visible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onDelete={onDelete}
      />
    </>
  );
};

EditUserModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.number.isRequired,
    last_name: PropTypes.string,
    locale: PropTypes.string,
    phone: PropTypes.string,
    profile_url: PropTypes.string,
    supplied_zip_code: PropTypes.string,
    team_name: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default EditUserModal;
