import { useEffect, useMemo, useState } from 'react';

// custom useQuery hook for getting query params with auto updates on history changes
export const useQueryParam = key => {
  const [search, setSearch] = useState(window.location.search);

  useEffect(() => {
    const onPopState = () => setSearch(window.location.search);
    window.addEventListener('popState', onPopState);
    window.addEventListener('pushState', onPopState); // optional custom events if using history.pushState
    window.addEventListener('replaceState', onPopState); // optional custom events if using history.replaceState

    return () => {
      window.removeEventListener('popState', onPopState);
      window.removeEventListener('pushState', onPopState);
      window.removeEventListener('replaceState', onPopState);
    };
  }, []);

  const value = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get(key);
  }, [search, key]);

  return value;
};

export default useQueryParam;
