import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ImpactiveOrCampaignLogo from '@web/components/Auth/Components/ImpactiveOrCampaignLogo';
import ImpactiveButton from '@web/components/ImpactiveButton';
import PoweredByImpactive from '@web/components/Auth/Components/PoweredByImpactive';

const CongratsText = styled.h1`
  display: flex;
  white-space: pre-wrap;
  margin-bottom: 2.5rem;
  text-align: center;
`;

const OnboardingCongrats = ({ campaign, onSubmit, type, isLastScreen, joinedTeamName }) => {
  const { t } = useTranslation();

  const congratsText = useMemo(
    () =>
      type === 'campaign'
        ? t('campaign.onboarding.campaign_congrats', { campaign_name: campaign?.name })
        : t('campaign.onboarding.team_congrats', { team_name: joinedTeamName }),
    [t, type, campaign, joinedTeamName],
  );

  const actionText = useMemo(
    () => (isLastScreen ? t('campaign.onboarding.get_started') : t('common.continue')),
    [t, isLastScreen],
  );

  if (!campaign) return null;

  return (
    <>
      <ImpactiveOrCampaignLogo campaign={campaign} withoutCampaignName />
      <CongratsText>{congratsText}</CongratsText>
      <ImpactiveButton size="large" onClick={onSubmit}>
        {actionText}
      </ImpactiveButton>
      <PoweredByImpactive withoutCampaignName />
    </>
  );
};

OnboardingCongrats.propTypes = {
  campaign: PropTypes.object,
  isLastScreen: PropTypes.bool.isRequired,
  joinedTeamName: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['campaign', 'team']).isRequired,
};

export default OnboardingCongrats;
