import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { message } from 'antd';
import VerificationInput from 'react-verification-input';

import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { users, campaigns, teams } from '../../services/api';
import { AuthContext } from '../../utils/context';
import { teamJoinCodeError } from '../../utils/errorHandler';
import Loading from '../common/Loading';
import ImpactiveOrCampaignLogo from './Components/ImpactiveOrCampaignLogo';
import PoweredByImpactive from './Components/PoweredByImpactive';
import LanguageSelect from './LanguageSelect';
import ImpactiveButton from '../ImpactiveButton';
import { setCampaignJoinModal, setTeamJoinModal } from 'web/services/session';

const JoinCampaign = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { campaign_slug } = useParams();
  const inputRef = useRef();
  const [code, setCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCampaignSetting, setIsCampaignSetting] = useState(false);

  const title = campaign_slug ? t('auth.sign_up') : t('auth.login');
  useDocumentTitle(title);

  const { user, onboardingData, campaign, isLoading, setCampaignSlug, setUser } =
    useContext(AuthContext);

  const setLastCampaignToUser = useCallback(
    id => {
      setIsCampaignSetting(true);
      // if id is present, get the campaign and follow it
      if (id) {
        campaigns.getCampaign({ id }).then(({ data: { data } }) => {
          users.followCampaign({
            campaign_id: data.id,
            campaign_name: data.name,
            campaign_slug: data.slug,
          });
          users.updateMe({ last_campaign_id: id }).then(({ data: { data } }) => {
            setUser(data);
          });
          setIsCampaignSetting(false);
          history.push(`/auth/complete-auth/${data.slug}`);
          setCampaignSlug(data.slug);
        });
        // else get the first campaign that user is following
      } else {
        users.getUserCampaigns(1, 200).then(({ data: { data } }) => {
          if (data?.length) {
            users.updateMe({ last_campaign_id: data[0].campaign.id }).then(({ data: { data } }) => {
              setUser(data);
            });
            setIsCampaignSetting(false);
            history.push(`/auth/complete-auth/${data[0].campaign.slug}`);
            setCampaignSlug(data[0].campaign.slug);
          } else {
            setIsCampaignSetting(false);
          }
        });
      }
    },
    [setCampaignSlug, setUser, history],
  );

  useEffect(() => {
    // wait for all the data to be loaded
    if (isLoading) {
      return;
    }

    // return to the previous page if no user is set
    if (!user) {
      history.goBack();
    } else {
      // navigate to complete the signup process if campaign_slug is present
      if (campaign_slug) {
        history.push(`/auth/complete-auth/${campaign_slug}`);
        // navigate to complete the signup process if user has last_campaign_id
      } else {
        // try to set last campaign id or locked campaign id or find the first campaign that user is following
        setLastCampaignToUser(user?.last_campaign_id || user?.locked_campaign_id);
      }
    }
    setCampaignSlug(campaign_slug);
  }, [campaign_slug, setCampaignSlug, user, isLoading, history, setLastCampaignToUser]);

  const joinTeamAndCampaign = useCallback(
    async data => {
      teams.joinTeam({ id: data.id });

      setCampaignSlug(data.campaign_slug);
      setIsSubmitting(false);

      setCampaignJoinModal();
      setTeamJoinModal(data.join_code);

      users.updateMe({ last_campaign_id: data.campaign_id }).then(({ data: { data } }) => {
        // after this user will be redirected to complete-auth page
        setUser(data);
      });
    },
    [setCampaignSlug, setIsSubmitting, setUser],
  );

  const joinCampaign = useCallback(
    async campaignFromCode => {
      users.followCampaign({
        campaign_id: campaignFromCode.id,
        campaign_name: campaignFromCode.name,
        campaign_slug: campaignFromCode.slug,
      });

      setCampaignSlug(campaignFromCode.slug);
      setIsSubmitting(false);

      setCampaignJoinModal();

      users.updateMe({ last_campaign_id: campaignFromCode.id }).then(({ data: { data } }) => {
        // after this user will be redirected to complete-auth page
        setUser(data);
      });
    },
    [setCampaignSlug, setUser],
  );

  const onSubmit = useCallback(() => {
    if (code?.length !== 6) {
      message.error(t('auth.join.invalid_code'));
    } else {
      setIsSubmitting(true);

      // try join to team by team code
      teams
        .find_by_code({ join_code: code })
        .then(({ data }) => {
          // success
          joinTeamAndCampaign(data.data);
        })
        .catch(error => {
          // try join to campaign by campaign code
          campaigns
            .findCampaign(code)
            .then(({ data }) => {
              if (!data) {
                setIsSubmitting(false);
                message.error(t('explore.join_code.error'));
              } else {
                // success
                joinCampaign(data.data);
              }
            })
            .catch(error => {
              setIsSubmitting(false);
              teamJoinCodeError(error);
            });
        });
    }
  }, [code, t, joinCampaign, setIsSubmitting, joinTeamAndCampaign]);

  const onChange = useCallback(value => {
    setCode(value);
  }, []);

  const onComplete = useCallback(value => {
    setCode(value);
    inputRef.current?.blur();
  }, []);

  const onFocus = useCallback(() => {
    if (code?.length === 6) {
      setCode(code.slice(0, 5));
    }
  }, [code]);

  if (isLoading || isCampaignSetting) {
    return (
      <div className="d-flex flex-row justify-content-center">
        <Loading />
      </div>
    );
  }

  if (!onboardingData.language) {
    return <LanguageSelect />;
  }

  return (
    <div
      className={`h-100 bg-auth-cyan d-flex flex-column align-items-center justify-content-center`}
    >
      <ImpactiveOrCampaignLogo campaign={campaign} />

      <div className="card fixed-auth">
        <div className={`card-title bolder mb-4`}>{t('auth.join.header')}</div>

        <div className="text-center">{t('auth.join.enter')}</div>
        <div className="text-center">{t('auth.join.dont_have_code')}</div>

        <VerificationInput
          ref={inputRef}
          value={code}
          classNames={{
            character: 'character',
            characterFilled: 'character--filled',
            characterInactive: 'character--inactive',
            characterSelected: 'character--selected',
            container: 'container',
          }}
          inputProps={{ disabled: isSubmitting, inputMode: 'numeric' }}
          validChars="0-9"
          placeholder="__"
          autoFocus
          length={6}
          onChange={onChange}
          onFocus={onFocus}
          onComplete={onComplete}
        />

        <div className="d-flex flex-row align-items-center justify-content-center mt-5 text-center">
          <ImpactiveButton size="large" disabled={isSubmitting} onClick={onSubmit}>
            {isSubmitting ? t('auth.join.joining') : t('common.submit')}
          </ImpactiveButton>
        </div>
      </div>

      {campaign && <PoweredByImpactive />}
    </div>
  );
};

JoinCampaign.propTypes = {};

export default JoinCampaign;
